<template>
    <div>
    	<div class="events-header">
	    	<h1 class="mb-0">events</h1>
	    	<div>
		    	<a class="a-btn" v-if="!account" href="#" v-on:click="connect($event)" style="min-width: 250px;"><button type="button" class="g-btn mt-3 glitch2" style="min-width: 250px;" data-text="CONNECT WALLET">CONNECT WALLET</button></a>
		    	<button v-else type="button" class="g-btn mt-3" @click="disconnect()" style="min-width: 175px;">LOGOUT</button>
		    </div>
	    </div>

	    <div class="mt-4" style="border: 1px solid; padding: 20px;">
		    <p class="mb-1 " style="font-size: 14px;"><b>Disclaimer:</b></p>
		    <p class="mb-0" style="text-align: justify;font-size: 14px;">Any privileged access is not guaranteed; guestlisted members have to understand the Berlin club culture, mentality, dress code, and door policy of the club. You can still be refused entry if you do not respect those factors, even if you're guestlisted. Join our discord and ask questions to our community to maximize your odds of getting in. Important: no photos are allowed inside the clubs. Good luck and have fun !</p>
		</div>

    	<div v-if="loading" class="loading-container">
        	<div class="spinner-border" role="status">
	          <span class="visually-hidden">Loading...</span>
	        </div>
	    </div>

	    <div v-else class="mt-3">
	        <div id="events-section">
	        	<div v-for="event in events" :key="event.id" class="event-item" :style="{'opacity': new Date() > new Date(this.moment(event.endDate).subtract(2, 'hours')) ? 0.5 : 1 }" :class="'id-' + event.id">
	        		<div class="event-item__inner">
	        			<a :href="event.link" target="blank_" class="event-link"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14 4h-13v18h20v-11h1v12h-22v-20h14v1zm10 5h-1v-6.293l-11.646 11.647-.708-.708 11.647-11.646h-6.293v-1h8v8z"/></svg></a>
	        			<p class="event-date">{{formatDate(event.startDate)}} - {{formatDate(event.endDate)}} (Berlin time)<br/><span style="opacity: 0.7;">Claimable until: {{formatDate(event.claimEndDate)}} (Berlin time)</span></p>
	        			<p class="event-date">WAVE: {{event.wawe}}</p>
	        			<h3 class="mt-3">{{event.name}}</h3>
	        			<p class="event-description">{{event.description}}</p>
	        			<div v-if="event.id === 2 || event.id === 5" class="notice">ATTENTION: No casual streetwear (like jeans) allowed. Come colorful, come kinky, be creative. Otherwise, you will be refused the entry.</div>
	        			<p class="event-venue"><b>Venue</b><br/>{{event.venue}}</p>
	        			<p class="event-djs"><b>DJs</b><br/> <template v-for="(DJ, index) in event.djs" :key="DJ">{{DJ}}<template v-if="index < event.djs.length - 1">, </template></template> </p>
	        			<p class="event-eligible-tokens"><b>Eligible tokens</b><br/>
	        				<span v-if="areAllTokensEligible(event)">
	        					All
	        				</span>
		        			<span v-else>
		        				<template v-for="(token, index) in event.eligibleTokens" :key="token"><template v-if="tokenWasUsedToClaimInTheWave(Number(token), event.wawe)"><s style="opacity:0.5;">{{token}}</s></template><template v-else>{{token}}</template><template v-if="index < event.eligibleTokens.length - 1">, </template>
		        				</template>
		        			</span>
		        		</p>
	        			<p class="event-claimed-tokens"><b>Claimed ({{event.claimed.length||0}}/{{event.maxClaimable}})</b><br/>
	        				<span v-if="event.claimed && event.claimed.length"><template v-for="(token, index) in event.claimed" :key="token">{{token}}<template v-if="index < event.claimed.length - 1">, </template></template></span>
	        				<span v-else>none</span>
	        			</p>
	        			<button class="g-btn mt-2" :class="{'disabled': eventButtonDisabled(event)}" :disabled="eventButtonDisabled(event)" @click="showEventModal(event)">{{eventButtonText(event)}}</button>
	        		</div>
	            </div>
	        </div>

	        <vue-final-modal 
	        	v-model="showModal" 
	        	content-class="event-modal-content"
	        	:click-to-close="false">
			    <div class="close-modal" @click="closeModal"></div>
			    <div v-if="viewedEvent">
	        		<h2 class="mb-4">{{viewedEvent.name}}</h2>
	        		<h6 class="mb-4">{{formatDate(viewedEvent.startDate)}} - {{formatDate(viewedEvent.endDate)}} (Berlin time)<br/><span style="opacity: 0.7;">CLAIMABLE UNTIL: {{formatDate(viewedEvent.claimEndDate)}} (Berlin time)</span></h6>
	        		<h6 class="mb-4"></h6>
	        		<h6>At {{viewedEvent.venue}}</h6>
	        		<p class="mb-4" style="max-width: 200px;font-size: 13px;">{{viewedEvent.address}}</p>
	        		<p class="mb-4">Remaining guest lists: {{viewedEvent.maxClaimable - (viewedEvent.claimed.length||0)}}/{{viewedEvent.maxClaimable}}</p>
	        		<div v-if="viewedEvent.id === 2 || viewedEvent.id === 5" class="notice">ATTENTION: No casual streetwear (like jeans) allowed. Come colorful, come kinky, be creative. Otherwise, you will be refused the entry.</div>
	        		<p class="mb-3" style="font-size: 14px;">Fill in your first and last name below in order to get your guest list (free entry + skip the queue) for this event (you will be able to edit it until {{formatDate(viewedEvent.claimEndDate)}} Berlin time). At the entry of the club: head directly to the door or to the "guest list" queue and tell your name to the bouncer.<br/><br/>
					Please, only claim your guest list if you will actually be located in Berlin at the time of the event and will be going to rave, otherwise you will not have any benefit from it.</p>
	        		
	        		<div v-for="(value, key) in names" :key="key" class="mb-3">
	        		  <p class="mb-1"><small>Guest list spot for token: #{{key.split('-')[0]}}</small></p>
	      			  <div class="input-group">
						  <input type="text" v-model="names[key]" placeholder="You first and last name" class="form-control"/>
						  <button class="input-group-text" @click="claimGuestlist(key.split('-')[0])">Submit</button>
						  <button v-if="isGuestlisted(viewedEvent, key.split('-')[0])" class="input-group-text" @click="revokeGuestlist(key.split('-')[0])">Revoke</button>
						</div>
					</div>

	        		<p class="mb-0 mt-5"><b>Disclaimer:</b></p>
	        		<p style="text-align: justify;">Any privileged access is not guaranteed; guestlisted members have to understand the Berlin club culture, mentality, dress code, and door policy of the club. You can still be refused entry if you do not respect those factors, even if you're guestlisted. Join our discord and ask questions to our community to maximize your odds of getting in. Important: no photos are allowed inside the clubs. Good luck and have fun !</p>
	        	</div>
	    	</vue-final-modal>

			<!-- <div class="text-center">
            	<button v-if="cursor !== null" type="button" class="g-btn mt-3" @click="fetchNFTs">
            		<template v-if="!btnLoading">
			            load more
			        </template>
					<template v-else>
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						<span class="sr-only"> loading...</span>
					</template>
            	</button>
            </div> -->
        </div>

    </div>
</template>

<script>
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import moment from "moment";
import {abi} from "@/ABI/abi"

export default {
    name: 'Events',
    components: {},
    data () {
        return {
        	web3Modal: null,
        	web3: null,
        	account: '',
        	showModal: false,
        	loading: true,
        	showNameError: false,
        	guestlists: null,
        	signature: localStorage.getItem('guestlistedSignature') || '',
        	names: {},
        	events: [],
        	moment: null,
        	ownedTokens: [],
        	viewedEvent: null,
        	fakeEvents: [{
        		id: 1,
        		wave: 1,
        		name: 'Expeditions N030',
        		description: 'Our captains might just be the right people to take you there this time. With carefully curated techno and warm, cozy house music, you\'ll be in good, steady hands.',
        		venue: '://about blank',
        		address: 'Markgrafendamm 24c, Friedrichshain, 10245 Berlin, Germany',
        		startDate: '13 may 2022 23:59',
        		endDate: '13 may 2022 10:00',
        		claimEndDate: '12 may 2022 23:59',
        		DJs: [
        			'Andre Kronert',
					'Edit Select',
					'NOIR',
					'Anna Schreit',
					'Dana Ruh',
					'DJ T.',
					'Glenn Holmes'
        		],
        		eligibleTokens: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        		claimed: [],
        		maxClaimable: 6,
        		link: 'https://ra.co/events/1520934'
        	}, {
        		id: 2,
        		wave: 1,
        		name: 'Paradise Garage: Reverence',
        		description: 'What makes it so sweet at the Paradise Garage? Is it the warm chords of the Soulful House spreading all over the green room, or is it the jumpy, ever-happy Disco vibe that caress the red room, stretching smiles over people’s faces? Perhaps it’s actually the Dream World Space that embraces guests and fills them with love, just before sending them back to the dance floor? Or maybe the Kinky-Booth, where people randomly pose and flirt with the camera? ATTENTION: No casual streetwear allowed. Come colorful, come kinky, be creative. ',
        		venue: 'Renate',
        		address: 'Alt Stralau 70, Friedrichshain, 10245 Berlin, Germany',
        		startDate: '13 may 2022 23:59',
        		endDate: '13 may 2022 10:00',
        		claimEndDate: '12 may 2022 23:59',
        		DJs: [
        			'Secret DJ lineup. Only the vibe matters.',
        		],
        		eligibleTokens: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231,232,233,234,235,236,237,238,239,240,241,242,243,244,245,246,247,248,249,250,251,252,253,254,255,256],
        		claimed: [],
        		maxClaimable: 6,
        		link: 'https://ra.co/events/1522179'
        	}]
        }
    },
    props: {},
    watch: {},
    beforeMount () {
    	this.moment = moment
    },
    mounted () {
    	this.fetchEvents()
    	this.initWeb3Modal()
    	let test = []
    	for (let i = 1; i < 257;i++) {
    		test.push(i)
    		console.log(JSON.stringify(test))
    	}
    },
    methods: {
    	formatDate (date) {
    		return this.moment(date).subtract(2, 'hours').format('MMMM Do HH:mm');
    	},
    	closeModal () {
    		this.showModal = false
    		this.viewedEvent = null
    		this.names = {}
    	},
    	isGuestlisted (event, tokenId) {
    		let result = false

    		this.guestlists.forEach(guestlist => {
    			if (guestlist.event.id === event.id && guestlist.tokenId === Number(tokenId)) {
    				result = true
    			}
    		})

    		return result
    	},
    	getEligibleTokensForEvent (event) {
    		let result = []

    		this.ownedTokens.forEach(ownedToken => {
    			if (event.eligibleTokens.indexOf(Number(ownedToken)) !== -1 &&
    				event.claimed.indexOf(Number(ownedToken)) === -1 &&
    				!this.tokenWasUsedToClaimInTheWave(Number(ownedToken), event.wawe)) {
    				result.push(ownedToken)
    			}
    		})

    		return result
    	},
    	areAllTokensEligible(event) {
    		let result = true
    		for (let i = 1; i <= 256; i++) {
    			if (event.eligibleTokens.indexOf(i) === -1) {
    				result = false
    				break
    			}
    		}
    		return result
    	},
    	eventButtonText (event) {
    		if (this.account) {
    			if (new Date() > new Date(this.moment(event.claimEndDate).subtract(2, 'hours'))) {
    				return 'Claim expired'
    			} else if (this.userHasGuestlistForEvent(event)) {
    				return 'Edit'
    			} else if (event.claimed.length >= event.maxClaimable) {
    				return 'The event is full'
    			} else if (this.userIsEligibleForEvent(event)) {
    				return 'Claim'
    			} else {
    				return 'Not eligible'
    			}
    		} else {
    			return 'Connect to claim'
    		}
    	},
    	eventButtonDisabled (event) {
    		if (this.account) {
    			if ((this.userIsEligibleForEvent(event) && event.claimed.length < event.maxClaimable) || this.userHasGuestlistForEvent(event)) {
	    			return false
	    		} else {
	    			return true
	    		}
    		} else {
    			return true
    		}
    	},
    	userIsEligibleForEvent (event) {
    		let result = false

		    this.ownedTokens.forEach((ownedToken) => {
		    	if (event.eligibleTokens.indexOf(Number(ownedToken)) !== -1 && 
		    		event.claimed.indexOf(Number(ownedToken)) === -1 &&
		    		!this.tokenWasUsedToClaimInTheWave(Number(ownedToken), event.wawe)) {
		    		result = true
		    	}
			})
			return result
    	},
    	userHasGuestlistForEvent (event) {
    		let result = false

    		this.ownedTokens.forEach(ownedToken => {
    			if (event.claimed.indexOf(Number(ownedToken)) !== -1) {
    				result = true
    			}
    		})

    		return result
    	},
    	tokenWasUsedToClaimInTheWave (token, wave) {
    		let result = false
    		this.events.forEach(event => {
    			if (event.wawe === wave && event.claimed.indexOf(token) !== -1) {
    				result = true
    			}
    		})
    		return result
    	},
    	async claimGuestlist (tokenId) {
    		if (!this.names[tokenId + '-' + this.viewedEvent.id] || this.names[tokenId + '-' + this.viewedEvent.id].length < 4) {
    			this.$notify("The name must be at least 4 caracters.");
    		} else {
	    		fetch("https://api.guestlisted.club/"+ this.account +"/events", {
	              method: 'POST',
	              headers: {
	                'Accept': 'application/json, text/plain, */*',
	                'Content-Type': 'application/json'
	              },
	              body: JSON.stringify({
	              	message: 'Log-in to guestlisted club.',
	              	signature: this.signature,
	              	tokenId: Number(tokenId),
	              	name: this.names[tokenId + '-' + this.viewedEvent.id],
	              	event: this.viewedEvent
	              })
	            })
	            .then(r => {
	            	if (r.status === 200) {
	            		return r.json()
	            	} else if (r.status === 403) {
	            		throw({customMessage: 'You can not claim a guest list for this event (the event is full or you already claimed a guest list in this wave).'})
	            	} else {
	            		throw({customMessage: 'An error occured, please, reload the page and retry.'})
	            	}
	            })
	            .then(async result => {
	                this.$notify("You successfully claimed a guest list.<br/>Have fun !");
	                this.viewedEvent = result.event
	                this.fetchEvents()
	                this.fetchGuestlists()
	            })
				.catch(error => {
					if (error.customMessage) {
						this.$notify(error.customMessage);
					} else {
						this.$notify("An error occured, please, reload the page and retry.");
					}
					console.log(error)
					return
				})  
    		}
    	},
    	async revokeGuestlist (tokenId) {
    		fetch("https://api.guestlisted.club/"+ this.account +"/events", {
              method: 'POST',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
              	message: 'Log-in to guestlisted club.',
              	signature: this.signature,
              	tokenId: Number(tokenId),
              	name: null,
              	event: this.viewedEvent
              })
            })
            .then(r => {
            	if (r.status === 200) {
            		return r.json()
            	} else {
            		throw({customMessage: 'An error occured, please, reload the page and retry.'})
            	}
            })
            .then(async result => {
                this.$notify("Guest list revoked successfully.");
                this.names[tokenId + '-' + this.viewedEvent.id] = ''

                // Remove element
                for( var i = 0; i < this.viewedEvent.claimed.length; i++){ 
			        if (this.viewedEvent.claimed[i] === Number(tokenId)) { 
			            this.viewedEvent.claimed.splice(i, 1); 
			        }
			    }

                this.fetchEvents()
                this.fetchGuestlists()
            })
			.catch(error => {
				if (error.customMessage) {
					this.$notify(error.customMessage);
				} else {
					this.$notify("An error occured, please, reload the page and retry.");
				}
				console.log(error)
				return
			})  
	},
    	async getOwnedTokens () {
    		const contract = new this.web3.eth.Contract(abi, process.env.VUE_APP_CONTRACT_ADDRESS);
			await contract.methods.tokensOfOwner(this.account).call()
				.then(async data => {
					this.ownedTokens = data
				})
				.catch(error => {
					console.log(error)
				})
    	},
    	showEventModal (event) {
    		if (this.account) {
    			let eligibleTokens = this.getEligibleTokensForEvent(event)
    			this.names = {}

    			this.guestlists.forEach(guestlist => {
    				if (guestlist.event.id === event.id) {
    					this.names[guestlist.tokenId + '-' + event.id] = guestlist.name
    				}
    			})

    			eligibleTokens.forEach(token => {
    				this.names[token + '-' + event.id] = ''
    			})

	    		this.viewedEvent = event
	    		this.showModal = true
    		} else {
    			this.connect()
    		}
    	},
    	fetchGuestlists () {
    		fetch("https://api.guestlisted.club/"+ this.account +"/events?message=Log-in to guestlisted club.&signature="+this.signature, {
              method: 'GET',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              }
            })
            .then(r => r.json())
            .then(result => {
                this.guestlists = result
            })
    	},
    	fetchEvents () {
    		fetch("https://api.guestlisted.club/events", {
              method: 'GET',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              }
            })
            .then(r => r.json())
            .then(result => {
                this.events = result.reverse()
    			this.loading = false
            })

    	},
    	initWeb3Modal () {
			const infuraId = process.env.VUE_APP_INFURA_ID

		    const providerOptions = {
				walletconnect: {
					package: WalletConnect,
					options: {
						infuraId
					}
				}
		    }

		    const web3Modal = new Web3Modal({
				network: "mainnet",
				cacheProvider: true,
				providerOptions,
				theme: {
				    background: "#161a1d",
				    main: "#ded2b9",
				    secondary: "#ded2b9",
				    border: "#ded2b9",
				    hover: "#2c2c2c"
				}
		    });

		    this.web3Modal = web3Modal

		    if (web3Modal.cachedProvider) {
				this.connect()
			}
		},
		async connect (e) {
			if (e) {
				e.preventDefault()
				e.stopPropagation()
			}

			let provider 

			try {
		    	provider = await this.web3Modal.connect()
		    } catch (e) {
		    	throw(e)
				return
		    }

		    provider.on("accountsChanged", (accounts) => {
				console.log('accountsChanged', accounts)
				this.disconnect()
			})

			// Subscribe to chainId change
			provider.on("chainChanged", (chainId) => {
				console.log("chainChanged", chainId)
				this.disconnect()
			})

			// Subscribe to provider connection
			provider.on("connect", ({ chainId }) => {
				console.log("connect", chainId)
			})

			// Subscribe to provider disconnection
			provider.on("disconnect", ({ code, message }) => {
				console.log("disconnect", message)
			})

			this.web3 = new Web3(provider)

			let accounts = await this.web3.eth.getAccounts()

			if (!this.signature) {
				let message = 'Log-in to guestlisted club.'
		        await this.web3.eth.personal.sign(message, accounts[0])
		          .then(async signature => {
		            let lastChar = signature.substr(signature.length-1)

		            if (lastChar === '0') {
		              signature = signature.substr(0, signature.length-2) + '1b'
		            } else if (lastChar === '1') {
		              signature = signature.substr(0, signature.length-2) + '1c'
		            }
		            
		            this.signature = signature
		            localStorage.setItem('guestlistedSignature', signature)
		            this.account = accounts[0]
		            await this.getOwnedTokens()
		            await this.fetchGuestlists()
		          })
		          .catch(error => {
		          	this.disconnect()
		            console.log(error)
		            return
		          })  
		    } else {
				this.account = accounts[0]
	            await this.getOwnedTokens()
	            await this.fetchGuestlists()
		    }
		},
		async disconnect () {
			await this.web3Modal.clearCachedProvider()
			this.account = ''
			this.signature = ''
			localStorage.setItem('guestlistedSignature', '')
		},
    }
}
</script>


<style lang="scss">
.loading-container {
	width: 100%;
	text-align: center;
	min-height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.events-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.event-modal-content {
	display: inline-block;
	padding: 24px;
	max-width: 600px;

    .close-modal {
        width: 58px;
        height: 68px;
        display: block;
        z-index: 100000;
        position: fixed;
        cursor: pointer;
        right: 7px;
        top: 0px;
        transition: transform .3s;
        background: url('~@/assets/img/close-light.svg') no-repeat center center;

    }
}



.vfm__overlay {
	background-color: #161a1d!important;
}

.input-group {
	button {
		background: #161a1d!important;
		border-radius: 0!important;
		border-color: #ded2b9!important;
		color: #ded2b9!important;
		height: 38px!important;

		&:focus {
			outline: none!important;
			box-shadow: none!important;
		}
	}
}

input {
	background: #161a1d!important;
	border-radius: 0!important;
	border-color: #ded2b9!important;
	color: #ded2b9!important;
	&:focus {
		outline: none!important;
		box-shadow: none!important;
	}
}

button.disabled {
	opacity: 0.5;

	&:after {
		opacity: 0;
	}
}

@media (max-width: 875px) {
	.events-header {
		display: block;

		.a-btn, button {
			width: 100%!important;
			min-width: 100%!important;
			max-width: 100%!important;
		}
	}
}
</style>